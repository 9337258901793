import { ApplicationRef, Component, HostListener } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { SwUpdate } from '@angular/service-worker';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Platform, ToastController } from '@ionic/angular';
import { concat, interval } from 'rxjs';
import { first } from 'rxjs/operators';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {
  public appPages = [
    {
      title: 'Home',
      url: '/home',
      icon: 'home'
    }
  ];

  constructor(
    private appRef: ApplicationRef,
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private update: SwUpdate,
    private toastCtrl: ToastController,
    private meta: Meta
  ) {
    this.initializeApp();
  }

  @HostListener('window:beforeinstallprompt', ['$event'])
  onbeforeinstallprompt(e) {
    // Prevent Chrome 67 and earlier from automatically showing the prompt
    e.preventDefault();
  }


  initializeApp() {
    const splash = document.getElementById('splash-screen');
    const appRoot = document.getElementById('app-root');


    this.platform.ready().then(() => {

      if (this.platform.is('cordova')) {
        this.statusBar.styleDefault();
        this.splashScreen.hide();
      }

      this.meta.addTag({ name: 'description', content: 'A beautiful way to plan your app' });
      this.meta.addTag({ name: 'author', content: 'Cactusoft' });
      this.meta.addTag({ name: 'keywords', content: 'planning-poker, poker, planning, cactusoft' });

      setTimeout(() => {
        splash.style.opacity = '0';
        appRoot.style.opacity = '1';
        setTimeout(() => {
          splash.remove();
        }, 1000);
      }, 1000);

      if (this.update.isEnabled) {

        const appIsStable$ = this.appRef.isStable.pipe(first(isStable => isStable === true));
        const everySixHours$ = interval(6 * 60 * 60 * 1000);
        const everySixHoursOnceAppIsStable$ = concat(appIsStable$, everySixHours$);
        everySixHoursOnceAppIsStable$.subscribe(() => this.update.checkForUpdate());

        this.update.available.subscribe(async evt => {
          const toast = await this.toastCtrl.create({
            message: `An update is available`,
            buttons: [{text: 'Update', handler: () => window.location.reload() }],
            position: 'bottom'
          });

          await toast.present();
        });
      }
    });
  }
}
