import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'board',
    loadChildren: () => import('./pages/player-board/player-board.module').then( m => m.PlayerBoardPageModule)
  },
  {
    path: 'game-master',
    loadChildren: () => import('./pages/game-master/game-master.module').then( m => m.GameMasterPageModule)
  },
  {
    path: 'blackcards',
    redirectTo: 'board/blackcards/500-501-502-503-504-505-506-507-508-509-510-511-512-513-514-515-516-517-518-519-520-521-522-523-524-525-526/master', // TODO: Set black cards
    pathMatch: 'full'
  },
  {
    path: '1',
    redirectTo: 'board/1/457-225-16-445-461-424-456-425-422-429-213-443-19-9-220-218-402-465-446-221-13-414-12-8-234-480-474/master', // TODO: Set cards
    pathMatch: 'full'
  },
  {
    path: '2',
    redirectTo: 'board/2/6-14-469-23-478-444-450-470-427-411-216-404-237-451-219-24-468-21-410-1-467-464-415-416-233-17-442/master', // TODO: Set cards
    pathMatch: 'full'
  },
  {
    path: '3',
    redirectTo: 'board/3/407-482-459-435-448-477-433-413-405-418-25-453-231-449-215-417-421-440-229-406-22-426-436-409-4-230-432/master', // TODO: Set cards
    pathMatch: 'full'
  },
  {
    path: '4',
    redirectTo: 'board/4/408-0-400-476-412-458-452-420-481-227-3-479-473-437-10-236-226-403-472-463-419-20-214-11-454-222-5/master', // TODO: Set cards
    pathMatch: 'full'
  },
  {
    path: '5',
    redirectTo: 'board/5/431-232-475-460-441-15-217-462-228-447-439-223-212-7-224-401-455-434-235-438-428-423-2-18-471-466-430/master', // TODO: Set cards
    pathMatch: 'full'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
